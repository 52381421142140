<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Email | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div
          v-if="loading"
          class="text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32"
          >
        </div>
        <div v-else>
          <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
            <h2 class="font-bold mb-2">
              Email Settings
            </h2>
            <p />
            <p v-if="!isGmailConnected">
              All outgoing emails will be sent from octoa.com. Set up your sender name and reply-to address.
            </p>
            <p v-else>
              Set up your sender name and sending Gmail address.
            </p>

            <div class="my-3">
              Sender name
              <div class="mt-2 w-64">
                <input
                  v-model="sender_name"
                  v-validate="{ required: true }"
                  name="name"
                  type="text"
                >
              </div>
              <div class="error mt-2">
                {{ errors.first('name') }}
              </div>
            </div>
            <div class="my-3">
              <span v-if="!isGmailConnected">Reply-to email address</span>
              <span v-else>Email address</span>
              <div class="mt-2 w-64">
                <input
                  v-model="email_address"
                  v-validate="{ required: true, email: true }"
                  name="email"
                  type="text"
                >
              </div>
              <div class="error mt-2">
                {{ errors.first('email') }}
              </div>
            </div>
            <hr class="my-5">

            <h2 class="font-bold mb-2">
              Gmail
            </h2>
            <p class="leading-normal">
              Connect your Gmail/Gsuite email account to be able to send emails directly from your own email. <br>This will significantly reduce spam rate.
            </p>

            <div v-if="!gmailLoading">
              <div
                v-if="!isGmailConnected"
                class="mt-3 cursor-pointer"
                @click="connectGmail"
              >
                <img
                  :src="require('@/assets/img/btn/btn-google-sign-in.svg')"
                  width="200"
                >
              </div>
              <div
                v-if="isGmailConnected"
                class="mt-3 cursor-pointer"
                @click="disconnectGmail"
              >
                <img
                  :src="require('@/assets/img/btn/btn-google-sign-out.svg')"
                  width="200"
                >
              </div>
            </div>
            <div v-else>
              <img
                src="@/assets/img/icons/loader.svg"
                width="50"
                class="mt-2"
              >
            </div>
            <hr class="my-5">
            <h2 class="font-bold my-2 inline-block">
              SMTP Settings

            </h2>
            <span class='label text-xs font-bold text-green ml-2' v-show="smtpMode">ON</span>
            <span class='label text-red text-xs font-bold ml-2' v-show="!smtpMode">OFF</span>
            <p class="leading-normal">
              Connect your own email provider
            </p>
            <div class="my-3">
              Host<div v-if="(!smtpEdit && smtpMode)" class="inline-block">: {{ smtp_host }}</div>
              <div class="mt-2 w-64" v-if="(smtpEdit && smtpMode) || !smtpMode">
                <input
                  v-model="smtp_host"
                  name="smtp_host"
                  type="text"
                  placeholder="smtp.example.com"
                >
              </div>

            </div>
            <div class="my-3">
              Port<div v-if="(!smtpEdit && smtpMode)" class="inline-block">: {{ smtp_port }}</div>
              <div class="mt-2 w-64" v-if="(smtpEdit && smtpMode) || !smtpMode">
                <input
                  v-model="smtp_port"
                  name="smtp_port"
                  type="text"
                  placeholder="587"
                >
              </div>

            </div>
            <div class="my-3">
              Username<div v-if="(!smtpEdit && smtpMode)" class="inline-block">: {{ smtp_username }}</div>
              <div class="mt-2 w-64" v-if="(smtpEdit && smtpMode) || !smtpMode">
                <input
                  v-model="smtp_username"
                  name="smtp_username"
                  type="text"
                  placeholder="Username"
                  autocomplete="off"
                >
              </div>

            </div>
            <div class="my-3">
              Password<div v-if="(!smtpEdit && smtpMode)" class="inline-block">: **************</div>
              <div class="mt-2 w-64" v-if="(smtpEdit && smtpMode) || !smtpMode">
                <input type="text" name="hidden" style="position:absolute;">
                <input
                  v-model="smtp_password"
                  name="smtp_password"
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                >
              </div>
              <div class="error mt-2">
                {{ errors.first('smtp_password') }}
              </div>
            </div>

            <div class="flex">
              <button
                v-if="!smtpEdit && smtpMode"
                class="button btn__content-text mt-2 mr-4"
                @click="showSmtpEdit"
              >
                Edit
              </button>
              <button
                v-show="!smtpLoading"
                class="button btn__content-text mt-2"
                @click="sendTestEmail"
              >
                Send a test email
              </button>
              <div>
                <img
                  v-show="smtpLoading"
                  src="@/assets/img/icons/loader.svg"
                  width="42"
                  class="mt-2"
                >
              </div>
            </div>
            <hr class="my-5">
            <h2 class="font-bold mb-5">
              Email signature
            </h2>
            <v-ckeditor v-model="content" />
          </div>
          <div class="w-full px-4 mt-4 lg:px-0 lg:mt-4 mb-20 text-right">
            <v-button @click="save">
              Save
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

  export default {
    name: 'SettingsEmail',
    data(){
      return {
        loading: true,
        gmailLoading: true,
        isSaving: false,
        isGmailConnected: false,
        content: '',
        sender_name: '',
        email_address: '',
        user: auth.user(),
        smtpEdit: false,
        smtpMode: false,
        smtp_host: '',
        smtp_port: '',
        smtp_username: '',
        smtp_password: '',
        smtp_existing_password: '',
        smtpError: false,
        smtpLoading: false,
      }
    },
    async mounted(){

      await this.refresh()

      if(this.$route.query.gmail_connect == 'success'){
        try {
          const postData = {
            code: this.$route.query.code
          }

          const { data } = await this.$api.get('oauth').gmailCallback(postData)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }

      this.gmailCheck()
    },
    methods: {
      showSmtpEdit(){
        this.smtpEdit = true
      },
      async gmailCheck(){
         try {
          const { data } = await this.$api.get('oauth').gmailCheck()
          this.isGmailConnected = data.isGmailConnected
          this.gmailLoading = false
        } catch(e){
          this.$toasted.global.api_error(e)
        }
      },
      async connectGmail(){
        try {
          const { data } = await this.$api.get('oauth').gmail()
          window.location = data.link
        } catch(e){
          this.$toasted.global.api_error(e)
        }
      },
      async disconnectGmail(){
        try {
          const { data } = await this.$api.get('oauth').gmailDisconnect()
          this.isGmailConnected = data.isGmailConnected
        } catch(e){
          this.$toasted.global.api_error(e)
        }
      },
      async refresh() {
        try {
          await this.$store.dispatch('auth/refreshUser')
          const email = this.$store.getters['auth/emailSignature']
          this.content = email

          await this.$store.dispatch('company/refreshCompanyDetails')
          const company = this.$store.getters['company/details']

          this.sender_name = company.senderName
          this.email_address = company.senderEmail
          this.smtp_host = company.smtpHost
          this.smtp_port = company.smtpPort
          this.smtp_username = company.smtpUsername
          this.smtp_password = ''
          this.smtp_existing_password = company.smtpPassword

          if(this.smtp_host && this.smtp_port && this.smtp_username && this.smtp_existing_password){
            this.smtpMode = true
            this.smtpEdit = false
          } else {
            this.smtpMode = false
            this.smtpEdit = true
          }

          auth.refreshUser()
          this.loading = false

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
      save(){
        this.isSaving = true
        this.$validator.validate().then(result => {
          if(result){
            this.doSave()
           }
        })
      },
      async doSave(){

        if(this.smtpEdit && this.smtp_password != ''){
          if(this.smtp_host == '' || this.smtp_port == '' || this.smtp_username == '' || this.smtp_password == ''){
            this.$toasted.global.general_error({
              message : 'Fill in all SMTP fields or remove them all.'
            })
            return false
          }

          await this.checkSmtp()
          if(this.smtpError){
            return false
          }
        }

        const postData = {
          userId: this.user.me.id,
          content: this.content,
          senderName: this.sender_name,
          senderEmail: this.email_address,
          smtp_username: this.smtp_username,
          smtp_password: this.smtp_password,
          smtp_port: this.smtp_port,
          smtp_host: this.smtp_host,
        }

        try {
          const { data } = await this.$api.get('settings').updateEmail(postData)
          this.$toasted.global.general_success({
            message : 'Email settings updated.'
          })
        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }

        await this.refresh()

      },
      async checkSmtp(){

        this.$toasted.clear()
        this.smtpError = false
        this.smtpLoading = true

        const postData = {
          senderName: this.sender_name,
          senderEmail: this.email_address,
          smtp_username: this.smtp_username,
          smtp_password: this.smtp_password,
          smtp_existing_password: this.smtp_existing_password,
          smtp_port: this.smtp_port,
          smtp_host: this.smtp_host,
        }

        try {
          const { data } = await this.$api.get('mail').smtpCheck(postData)
          this.smtpLoading = false
        } catch(e){
          this.smtpLoading = false
          this.smtpError = true
          this.$toasted.global.general_error({
              message : 'Error connecting to SMTP host, please check again.'
          })
        }
      },
      async sendTestEmail(){
        await this.checkSmtp()

        if(!this.smptError){
          const postData = {
            userId: this.user.me.id,
            senderName: this.sender_name,
            senderEmail: this.email_address,
            smtp_username: this.smtp_username,
            smtp_password: this.smtp_password,
            smtp_existing_password: this.smtp_existing_password,
            smtp_port: this.smtp_port,
            smtp_host: this.smtp_host,
          }

          try {
            const { data } = await this.$api.get('mail').smtpTest(postData)
            this.smtpLoading = false
            this.$toasted.global.general_success({
              message : 'Test email sent!'
            })
          } catch(e){
            this.smtpError = true
            this.smtpLoading = false
          }
        }
      }
    },
  }
</script>
